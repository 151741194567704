import { REACT_APP_API_URL, REACT_APP_AUTH_SECRET_kEY } from "../../Components/processENV";
import axios from "axios";
import { handleApiFailedError, internetFailedStatus } from "./helpers";
import {
  getFromLocalStorageAndDecrypt,
  encryptAndStoreInLocalStorage
} from "../../Components/WebChat/WebChatEncryptDecrypt";
import Store from '../../Store';
import { fetchUserInfoRemove } from "../../Actions/UserInformationAction";
import SDK from "../../Components/SDK";
import { blockOfflineAction, logout } from "../../Helpers/Utility";
import { AGENTLOGIN } from '../../Helpers/Constants';

class ApiService {
  constructor(
    baseURL,
    customConfig = {
      Mode: "api",
      authorizationKey: REACT_APP_AUTH_SECRET_kEY
    }
  ) {
    this.instance = axios.create({
      baseURL: baseURL || REACT_APP_API_URL
      // timeout: 5000 // Adjust as needed
    });
    this.abortControllers = {
      get: null,
      post: null,
      put: null,
      delete: null
    };

    // Request interceptor
    this.instance.interceptors.request.use(
      (config) => {
        config.headers["Content-Type"] = "application/json" ;

        // If a token exists, add it to the request headers
        const authUser = getFromLocalStorageAndDecrypt("token");

        config.headers["Authorization"] = authUser !== null ? authUser : customConfig.authorizationKey;

        if (customConfig?.headers) {
          config.headers = { ...config.headers, ...customConfig.headers };
        }

        return { ...customConfig, ...config };
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.refreshTokenInProgress = false;
    this.tokenRefreshPromise = null;

    // Response interceptor
    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (!this.refreshTokenInProgress) {
          const originalRequest = error.config;
          const loggedInRole = getFromLocalStorageAndDecrypt("auth_user_role");
          if(error?.response?.data?.status == 401 && loggedInRole == "agent") {
            localStorage.clear();
            Store.dispatch(fetchUserInfoRemove());
            window.location.replace("/agent-login");
          }
          // If the error status is 401 and the request hasn't already been retried
          if (error?.response?.data?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            // Set a flag to prevent multiple simultaneous token refresh attempts
            this.refreshTokenInProgress = true;

            // Refresh the token and retry the original request
            return this.refreshToken()
              .then((res) => {
                if (res.status === 200 && res?.data?.token) {
                  SDK.setUserToken(res.data.token);
                  encryptAndStoreInLocalStorage("token", res.data.token);
                  return this.recallOriginalRequest(originalRequest, res.data.token);
                } else {
                  throw res;
                }
              })
              .catch(async (error) => {
                if (error?.status === 401) {
                  return this.refreshToken()
                    .then((res) => {
                      if (res.status === 200 && res?.data?.token) {
                        SDK.setUserToken(res.data.token);
                        encryptAndStoreInLocalStorage("token", res.data.token);
                        return this.recallOriginalRequest(originalRequest, res.data.token);
                      } else {
                        logout();
                      }
                    })
                    .catch((error) => {
                      logout();
                    });
                }
                return Promise.reject(error);
              })
              .finally(() => {
                this.refreshTokenInProgress = false;
              });
          }
        }
        // If the error is not related to token expiration, reject the promise
        return Promise.reject(error);
      }
    );
  }

  cancelPreviousRequest(type, context = "default") {
    const key = `${type}-${context}`;

    if (this.abortControllers[key]) {
      this.abortControllers[key].abort();
    }

    this.abortControllers[key] = new AbortController();
    return this.abortControllers[key].signal;
  }

  async get({ url = "", params = {}, onSuccess, onError, existApiCallDisable = false, context = "default" }) {
   
  
    try {
      const response = await this.instance.get(url, { params: { ...params }, signal: existApiCallDisable && this.cancelPreviousRequest("get", context)});

      
      if (response.config.Mode === "media") {
        onSuccess(response.data);
        return response.data;
      }

      if (response?.data?.status === 200 || response?.data?.status === 204) {
        onSuccess(response.data);
        return response.data;
      }


      throw response.data;
    } catch (error) {
      if(error?.code != 'ERR_CANCELED'){
        onError(error);
     }
      return error;
    }
  }

  async post({ url = "", payload, onSuccess, onError ,existApiCallDisable = false, context = "default"}) {
    try {
      if (blockOfflineAction()) {
        throw internetFailedStatus;
      }
      const response = await this.instance.post(url, payload, {signal: existApiCallDisable && this.cancelPreviousRequest("post", context)});

      if (response?.data?.status === 200 || response?.data?.status === 204 || response?.data?.status === 202) {
        onSuccess(response.data);
        return response.data;
      }
      throw response.data;
    } catch (error) {

      if(error?.code != 'ERR_CANCELED'){
         onError(error);
      }
      return error;
    }
  }

  async put({ url = "", payload = {}, onSuccess, onError,existApiCallDisable = false }) {
    try {
      if (blockOfflineAction()) {
        throw internetFailedStatus;
      }
      const response = await this.instance.put(url, payload,{signal: existApiCallDisable && this.cancelPreviousRequest("put")});
      if (response?.data?.status === 200) {
        onSuccess(response.data);
        return response.data;
      }
      throw response.data;
    } catch (error) {
      onError(error);
      return error;
    }
  }

  async delete({ url = "", payload = {}, onSuccess, onError,existApiCallDisable = false }) {
    try {
      if (blockOfflineAction()) {
        throw internetFailedStatus;
      }
      const response = await this.instance.delete(url, {data:payload, signal: existApiCallDisable && this.cancelPreviousRequest("delete")});
      if (response?.data?.status === 200) {
        onSuccess(response.data);
        return response.data;
      }
      throw response.data;
    } catch (error) {
      onError(error);
      return error;
    }
  }

  async deleteWithRequestBody({ url = "", payload = {}, onSuccess, onError ,existApiCallDisable = false}) {
    try {
      if (blockOfflineAction()) {
        throw internetFailedStatus;
      }
      const authUser = getFromLocalStorageAndDecrypt("token");
      const response = await axios.delete( REACT_APP_API_URL+url , { data: payload ,headers: {
        Authorization: authUser 
      },})
      if (response?.data?.status === 200 || response?.data?.status === 204) {
        onSuccess(response.data);
        return response.data;
      }
      throw response.data;
    } catch (error) {
      onError(error);
      return error;
    }
  }

  async handleApiError(error) {
    handleApiFailedError(error);
  }
  refreshToken() {
    return new Promise(async (resolve, reject) => {
      try {
        // this.refreshTokenInProgress = true;
        const apiService = new ApiService(REACT_APP_API_URL);
        const authUser = getFromLocalStorageAndDecrypt("auth_user");
        // Call your API endpoint to refresh the token
        const response = await apiService.post("/login", {
          username: authUser.username,
          password: authUser.password,
          type: "WEB"
        });
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      } catch (error) {
        reject(error);
        // reject(`Refresh token failed: ${error.message}`);
      } finally {
        this.refreshTokenInProgress = false;
      }
    });
  }

  recallOriginalRequest(originalRequest, token) {
    originalRequest.headers["Authorization"] = token;
    return axios(originalRequest);
  }
}

export default ApiService;
